// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("F:\\HajratStudio\\fastigo_website\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("F:\\HajratStudio\\fastigo_website\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-js": () => import("F:\\HajratStudio\\fastigo_website\\src\\pages\\kariera.js" /* webpackChunkName: "component---src-pages-kariera-js" */),
  "component---src-pages-kontakt-js": () => import("F:\\HajratStudio\\fastigo_website\\src\\pages\\kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-jakosci-js": () => import("F:\\HajratStudio\\fastigo_website\\src\\pages\\polityka-jakosci.js" /* webpackChunkName: "component---src-pages-polityka-jakosci-js" */),
  "component---src-pages-zlecenia-spedycyjne-js": () => import("F:\\HajratStudio\\fastigo_website\\src\\pages\\zlecenia-spedycyjne.js" /* webpackChunkName: "component---src-pages-zlecenia-spedycyjne-js" */)
}

